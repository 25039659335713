:root {
  --navy: #0A1931;
  --gold: #C6A85C;
  --light-gold: #DEC38E;
  --dark-navy: #060D19;
  --white: #ffffff;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--navy);
  color: var(--white);
  font-family: 'Arial', sans-serif;
  min-height: 100vh;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

h1 {
  text-align: center;
  color: var(--gold);
  font-size: 2.5rem;
  margin-bottom: 40px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  letter-spacing: 1px;
}

.search-form {
  margin: 20px auto 40px;
  max-width: 600px;
  display: flex;
  gap: 10px;
  background: var(--dark-navy);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(198, 168, 92, 0.2);
}

.search-input {
  flex: 1;
  padding: 15px 20px;
  border: 2px solid var(--gold);
  border-radius: 8px;
  background-color: var(--dark-navy);
  color: var(--white);
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.search-input:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(198, 168, 92, 0.3);
  border-color: var(--light-gold);
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.search-button {
  padding: 15px 30px;
  background-color: var(--gold);
  color: var(--dark-navy);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.search-button:hover {
  background-color: var(--light-gold);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(198, 168, 92, 0.2);
}

.news-list {
  display: grid;
  gap: 25px;
  padding: 20px;
}

.news-item {
  background-color: var(--dark-navy);
  padding: 25px;
  border-radius: 12px;
  border: 1px solid rgba(198, 168, 92, 0.2);
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.news-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(198, 168, 92, 0.15);
  border-color: var(--gold);
}

.news-item h3 a {
  color: var(--gold);
  text-decoration: none;
  font-size: 1.2rem;
  transition: all 0.3s ease;
}

.news-item h3 a:hover {
  color: var(--light-gold);
  text-decoration: underline;
}

.news-item p {
  color: var(--white);
  line-height: 1.6;
  margin-bottom: 15px;
  opacity: 0.9;
}

.news-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid rgba(198, 168, 92, 0.2);
}

.news-meta a {
  color: var(--gold);
  text-decoration: none;
  padding: 8px 16px;
  border: 1px solid var(--gold);
  border-radius: 6px;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}

.news-meta a:hover {
  background-color: var(--gold);
  color: var(--dark-navy);
}

.news-meta span {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
}

.loading {
  text-align: center;
  color: var(--gold);
  padding: 20px;
  font-size: 1.2rem;
}

.error-message {
  background-color: rgba(255, 99, 71, 0.1);
  color: #ff6347;
  padding: 15px;
  border-radius: 8px;
  margin: 20px 0;
  text-align: center;
  border: 1px solid rgba(255, 99, 71, 0.3);
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .container {
    padding: 20px 10px;
  }

  h1 {
    font-size: 2rem;
  }

  .search-form {
    flex-direction: column;
    padding: 15px;
  }

  .search-button {
    width: 100%;
  }

  .news-item {
    padding: 20px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
  padding: 20px;
}

.pagination-button {
  background-color: var(--gold);
  color: var(--dark-navy);
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.pagination-button:hover {
  background-color: var(--light-gold);
  transform: translateY(-2px);
}

.pagination-info {
  color: var(--gold);
  font-size: 1.1rem;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
  width: 100%;
}

.channel-button {
  position: absolute !important;
  right: 30px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  color: #ff3333 !important;
  font-size: 1.8rem !important;
  font-weight: bold !important;
  text-decoration: none !important;
  transition: all 0.3s ease !important;
  white-space: nowrap !important;
  z-index: 1000 !important;
  padding: 10px 20px !important;
}

.channel-button:hover {
  color: #ff0000 !important;
  transform: translateY(-50%) translateX(-5px) !important;
  text-decoration: none !important;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .channel-button {
    position: static !important;
    transform: none !important;
    font-size: 1.4rem !important;
    margin-top: 20px !important;
  }

  .channel-button:hover {
    transform: translateX(-5px) !important;
  }
} 