.keyword-card {
  background: rgba(20, 24, 46, 0.7);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 30px;
  width: 100%;
  border: 1px solid rgba(212, 175, 55, 0.2);
  backdrop-filter: blur(10px);
}

.keyword-card h3 {
  color: #D4AF37;
  margin: 0 0 15px 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.keyword-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.keyword-chip {
  background: rgba(212, 175, 55, 0.1);
  border: 1px solid rgba(212, 175, 55, 0.3);
  border-radius: 50px;
  padding: 8px 16px;
  color: #D4AF37;
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.keyword-chip:hover {
  background: rgba(212, 175, 55, 0.2);
  transform: translateY(-2px);
}

.keyword-count {
  background: rgba(212, 175, 55, 0.2);
  border-radius: 50%;
  padding: 2px 8px;
  font-size: 0.8rem;
}

@media (max-width: 768px) {
  .keyword-card {
    padding: 15px;
  }
  
  .keyword-chip {
    font-size: 0.9rem;
  }
} 