:root {
  --primary-color: #D4AF37;
  --bg-color: #0f172a;
  --card-bg: rgba(255, 255, 255, 0.03);
  --text-primary: #fff;
  --text-secondary: #94a3b8;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-color);
  background-image: 
    radial-gradient(circle at 100% 0%, rgba(212, 175, 55, 0.1) 0%, transparent 50%),
    radial-gradient(circle at 0% 100%, rgba(212, 175, 55, 0.05) 0%, transparent 50%);
}

.App-header {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.youtube-link {
  color: var(--primary-color);
  text-decoration: none;
  padding: 12px 24px;
  border: 1px solid var(--primary-color);
  border-radius: 50px;
  transition: all 0.3s ease;
  font-weight: 500;
  letter-spacing: 0.5px;
  background: rgba(212, 175, 55, 0.05);
}

.youtube-link:hover {
  background: var(--primary-color);
  color: var(--bg-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(212, 175, 55, 0.2);
}

.App-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.search-container {
  width: 100%;
  max-width: 600px;
  margin: 40px auto;
  animation: fadeIn 0.5s ease;
}

.results-container {
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  animation: slideUp 0.5s ease;
}

.loading {
  color: var(--primary-color);
  margin: 20px 0;
  font-size: 1.2rem;
  animation: pulse 1.5s infinite;
}

.error {
  color: #ef4444;
  margin: 20px 0;
  font-size: 1.2rem;
  padding: 15px 25px;
  background: rgba(239, 68, 68, 0.1);
  border-radius: 8px;
  animation: shake 0.5s ease;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { 
    opacity: 0;
    transform: translateY(20px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
}

@media (max-width: 768px) {
  .App-header {
    padding: 15px;
  }

  .youtube-link {
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  .search-container {
    padding: 0 20px;
    margin: 20px auto;
  }
  
  .results-container {
    padding: 0 20px;
  }
}